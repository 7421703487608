import React from 'react'
import { Link } from 'gatsby'
import Navbar from '../components/Navbar/Navbar'
import Wave from '../components/Wave';
import Footer from '../components/footer'
import './faq.css'
import styled from 'styled-components'
import Head from '../components/head'



const NotButton = styled.button`
padding: 8px 20px;
font-size: 16px;
background: -webkit-gradient(linear, left top, right top, from(rgb(245, 161, 36)), to(rgb(216, 43, 99)));
background: linear-gradient(to right, rgb(245, 161, 36) 0%, rgb(216, 43, 99) 100%);
border: none;
font-weight: 700;
text-transform:uppercase;
letter-spacing:2px;
border-radius: 10px;
outline: none;
cursor: pointer;
transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
color:#f1f1f1;
}
`

const NotFound = () => {
  return(
      <div>
                <Head title="Page Not Found" />

          <Navbar />
          <div className="Hero404">
      <div className="Hero404Group">
       
        
        <Wave />
      </div>
    </div>
    <div className="H404">
<p> <Link to="/">Return to Homepage</Link></p></div>

<Footer />
</div>
  )   
}

export default NotFound